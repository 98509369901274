import client from './';

export const createOIDCClient = (name) => {
  const data = { name };
  return client.post('/oidc/client', data);
};

export const deleteOIDCClient = (oidcClientId) => {
  return client.delete(`/oidc/client/${oidcClientId}`);
};

export const getOIDCClientList = () => {
  return client.get('/oidc/client-list');
};

export const getOIDCClient = (clientId) => {
  return client.get(`/oidc/client/${clientId}`);
};

export const createOIDCClientDomain = (clientId, domain, env) => {
  const data = { domain, env };
  return client.post(`/oidc/client/${clientId}/domain`, data);
};

export const deleteOIDCDomain = (clientId, domainId) => {
  return client.delete(`/oidc/client/${clientId}/domain/${domainId}`);
};

export const addOIDCClientClient = (oidcClientId, clientId) => {
  const data = { id: clientId };
  return client.post(`/oidc/client/${oidcClientId}/client`, data);
};

export const removeOIDCClientClient = (oidcClientId, clientId) => {
  return client.delete(`/oidc/client/${oidcClientId}/client/${clientId}`);
};
