import Vue from 'vue';
import VueCookies from 'vue-cookies';

import './styles/fonts.css';
import './styles/global.css';

import ActivateAccountPage from './pages/ActivateAccountPage';
import ContactSupportPage from './pages/ContactSupportPage';
import ErrorPage from './pages/ErrorPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoadingPage from './pages/LoadingPage';
import LoginPage from './pages/LoginPage';
import OIDCClientEditPage from './pages/OIDCClientEditPage';
import OIDCClientListPage from './pages/OIDCClientListPage';

// allow the use of setting cookies in our components
Vue.use(VueCookies);

export default {
  ActivateAccountPage,
  ContactSupportPage,
  ErrorPage,
  ForgotPasswordPage,
  LoadingPage,
  LoginPage,
  OIDCClientEditPage,
  OIDCClientListPage,
};