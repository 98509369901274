<template>
  <ExternalTemplate v-if="tokenIsValid">
    <div id="header-container">
      <h1>Activate Account</h1>
      <p>{{ subheading }}</p>
    </div>
    <form @submit.prevent="onFormSubmit">
      <PasswordInput
        id="password-input"
        :placeholder="'Password'"
        @onChange="onPasswordChange"
      />
      <PasswordInput
        id="confirm-password-input"
        :error="passwordError"
        :placeholder="'Confirm Password'"
        @onChange="onConfirmPasswordChange"
      />
      <div class="button">
        <Button :type="'submit'" :disabled="!canActivate">
          Activate Account
          <img :src="arrowImg" />
        </Button>
      </div>
    </form>
  </ExternalTemplate>
</template>

<script>
import { activateAccount, checkToken } from '../api/activate';
import Button from '../components/Button';
import ExternalTemplate from '../components/ExternalTemplate';
import PasswordInput from '../components/PasswordInput';
import { isValidPassword } from '../utils';

// Button icons
import arrowRight from '../assets/img/arrow-right.svg';
import arrowRightDisabled from '../assets/img/arrow-right-disabled.svg';

const subheading = 'To activate your account,\nchoose a password that is at least 8 characters';

export default {
  name: 'ActivateAccountPage',
  components: {
    Button,
    ExternalTemplate,
    PasswordInput
  },
  data() {
    const { username, token } = this.getQueryStringParams();

    return {
      confirmPassword: '',
      error: null,
      password: '',
      subheading,
      username,
      token,
      tokenIsValid: false
    };
  },
  computed: {
    arrowImg() {
      return this.canActivate ? arrowRight : arrowRightDisabled;
    },
    canActivate() {
      const passwordsOk = isValidPassword(this.password) && this.passwordsMatch;

      return this.tokenIsValid && passwordsOk;
    },
    passwordError() {
      if (this.confirmPassword !== '' && !this.passwordsMatch) {
        return 'Passwords do not match';
      }

      return null;
    },
    passwordsMatch() {
      return this.password === this.confirmPassword;
    }
  },
  created() {
    checkToken(this.username, this.token).then(() => {
      this.tokenIsValid = true;
    }, (error) => {
      const { message } = error.response.data;
      let href = '/error.html';

      if (message) {
        const encodedMessage = btoa(message);
        href += `?message=${encodedMessage}`;
      }

      window.location.href = href;
    });
  },
  methods: {
    getQueryStringParams() {
      const urlParams = new URLSearchParams(window.location.search);

      // Get 'token' and 'username' parameters
      const token = urlParams.get('token');
      const username = urlParams.get('username');

      return { token, username };
    },
    onConfirmPasswordChange(password) {
      this.confirmPassword = password;
    },
    onFormSubmit() {
      if (this.canActivate) {
        activateAccount(this.username, this.password, this.token).then(() => {
          window.location.href = `/login.html?login_hint=${this.username}`;
        }, (error) => {
          const { message } = error.response.data;
          const encodedMessage = btoa(message);

          window.location.href = `/error.html?message=${encodedMessage}`;
        });
      }
    },
    onPasswordChange(password) {
      this.password = password;
    }
  }
};
</script>

<style scoped>
#header-container > p {
  color: #434343;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-top: 12px;
  white-space: pre-line;
}

form {
  width: 100%;
  max-width: 311px;
  margin-top: 52px;
}

.button {
  margin-top: 32px;
}
</style>