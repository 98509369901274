<template>
  <ExternalTemplate :beta="isBeta">
    <div class="container">
      <h2 class="title">
        Account not authorized
      </h2>
      <p>Contact the AccuWeather Support team to activate account for use within the Web Portal</p>
      <div>
        <Button :styling="'primary'" :link="'/login.html'">
          Back to sign in
          <img src="../assets/img/arrow-right.min.svg" />
        </Button>
      </div>
    </div>
  </ExternalTemplate>
</template>

<script>
import Button from '../components/Button';
import ExternalTemplate from '../components/ExternalTemplate';

export default {
  name: 'LoginErrorPage',
  components: {
    Button,
    ExternalTemplate,
  },
};
</script>

<style scoped>
.container {
  max-width: 355px;
  margin: 8px;
}

.container > p {
  font-size: 14px;
  margin-bottom: 32px;
}

.title {
  text-align: center;
}

.input-actions {
  margin: 32px 0 0;
}
</style>
