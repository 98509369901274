<template>
  <div class="client-list-container">
    <ErrorMessage v-if="error" :error="error" />
    <div
      v-for="client in list"
      :key="client.id"
      class="client-item"
      @click="onClientClick(client)"
    >
      {{ client.name }}
    </div>
  </div>
</template>

<script>
import { getClients } from '../api/client';
import ErrorMessage, { getErrorFromResponseError } from '../components/ErrorMessage';

export default {
  name: 'ClientList',
  components: {
    ErrorMessage,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
  },
  data: function() {
    return {
      error: null,
      list: [],
      loading: false,
    };
  },
  watch: {
    name: function() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      getClients(this.name)
        .then((response) => {
          this.list = response.data.clientList;
          this.loading = false;
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
    onClientClick(client) {
      this.$emit('on-client-click', client);
    }
  },
};
</script>

<style scoped>
.client-item {
  cursor: pointer;
}
.client-list-container {
  min-height: 200px;
}
</style>
