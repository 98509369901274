<template>
  <div class="input password-input">
    <Input
      :error="error"
      :name="'password'"
      :placeholder="placeholder"
      :type="showPassword ? 'text' : 'password'"
      :value="password"
      @onInputChange="onPasswordChange"
    >
      <img class="icon" :src="showPasswordImage" @click="onShowPasswordClick" />
    </Input>
  </div>
</template>

<script>
import Input from './Input';

// Input icons
import eyeAltImage from '../assets/img/eye-alt.svg';
import eyeImage from '../assets/img/eye.svg';

export default {
  name: 'PasswordInput',
  components: {
    Input,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Password'
    },
    value: {
      type: String,
      default: null,
    },
  },
  data(props) {
    return {
      password: props.value || '',
      showPassword: false,
    };
  },
  computed: {
    showPasswordImage() {
      return this.showPassword ? eyeImage : eyeAltImage;
    }
  },
  methods: {
    onPasswordChange(text) {
      this.password = text;

      this.$emit('onChange', this.password);
    },
    onShowPasswordClick() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<style scoped>
.password-input .icon {
  position: absolute;
  bottom: 11px;
  right: 0;
}
</style>