<template>
  <GlobalTemplate>
    <div id="container">
      <div id="logo-container">
        <img src="../assets/img/afb-stacked-transparent-logo.png" />
      </div>
      <div id="content">
        <slot />
      </div>
      <div v-if="beta">
        <BetaStatement :is-beta="beta" />
      </div>
      <div class="push" />
    </div>
    <div id="footer">
      <a class="link" href="https://www.accuweather.com/en/legal">Terms &amp; Conditions</a>
      <a class="link" href="/contact-support.html">Contact Support</a>
    </div>
  </GlobalTemplate>
</template>

<script>
import BetaStatement from './BetaStatement';
import GlobalTemplate from './GlobalTemplate';

export default {
  name: 'ExternalTemplate',
  components: {
    BetaStatement,
    GlobalTemplate,
  },
  props: {
    beta: Boolean,
  },
};
</script>

<style scoped>
html, body, input {
  font-family: Solis, Arial, Helvetica, sans-serif;
}

#container {
  min-height: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -132px;
}

#logo-container {
  min-height: 195px;
  margin: 135px 0 60px;
}

#logo-container > img {
  width: 200px;
}

#content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.push {
  width: 100%;
  height: 132px;
}

#footer {
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#footer > :not(:first-child) {
  margin-left: 12px;
}

@media only screen and (max-height: 1000px) {
  #logo-container {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  #logo-container {
    margin-top: 40px;
  }
}
</style>