<template>
  <ExternalTemplate>
    <img :src="loadingAnimation" width="60" />
  </ExternalTemplate>
</template>

<script>
import ExternalTemplate from '../components/ExternalTemplate';

// Loading Spinner
import loadingAnimation from '../assets/img/loading-animation.gif';

export default {
  name: 'LoadingPage',
  components: {
    ExternalTemplate,
  },
  data() {
    return {
      loadingAnimation
    };
  }
};
</script>