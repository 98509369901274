<template>
  <ExternalTemplate>
    <p class="header">{{ message }}</p>
    <div id="support-email">
      <a class="link">AFB.Support@accuweather.com</a>
    </div>
  </ExternalTemplate>
</template>

<script>
import ExternalTemplate from '../components/ExternalTemplate';

const genericErrorMessage = 'An error has occurred. Please contact your administrator.';

export default {
  name: 'ErrorPage',
  components: {
    ExternalTemplate,
  },
  data() {
    const { message } = this.getQueryStringParams();

    return {
      message
    };
  },
  methods: {
    getQueryStringParams() {
      const bogusMessages = ['null', 'undefined'];
      const urlParams = new URLSearchParams(window.location.search);

      // Get 'message' query string parameter
      const message = urlParams.get('message');

      // 'message' may be empty, invalid, or not included at all
      if (message && !bogusMessages.includes(message)) {
        try {
          const decodedMessage = atob(message);

          // Decoded message may not be a valid error message
          if (!bogusMessages.includes(decodedMessage)) {
            return { message: decodedMessage };
          }
        } catch {
          console.error('Received bogus "message" query string parameter value');
        }
      }

      return { message: genericErrorMessage };
    },
  }
};
</script>

<style scoped>
.header {
  max-width: 600px;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

#support-email {
  margin-top: 32px;
}

#support-email > .link {
  color: #f05514;
}
</style>