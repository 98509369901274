export const getEnvironment = () => {
  const hostName = window.location.origin;

  if (hostName === 'https://enterpriseportal-v2.accuweather.com' ||
      hostName === 'https://enterpriseportal.accuweather.com') {
    return 'production';
  }

  if (hostName === 'https://enterpriseportal-staging.accuweather.com') {
    return 'staging';
  }

  return 'development';
};

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPassword = (password) => {
  // Password must be at least 8 characters
  return password.length >= 8;
};

export const isValidPhone = (phone) => {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(phone);
};

// export const initRecaptcha = (id) => {
//   // set recaptcha to the rendered value
//   // to allow us to get the token when form is submitted
//   return grecaptcha.render(id, {
//     sitekey: '6LdAw3sUAAAAAL_75ynHkt6qhniMHafSZ4kegDqt',
//   });
// };
