<template>
  <div id="global-template">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GlobalTemplate',
};
</script>

<style scoped>
#global-template {
  height: 100%;
  font-family: Solis, Arial, Helvetica, sans-serif;
}

/* This targets every occurrence of .input *except* for the first */
/* Source: https://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class */
.input ~ .input {
  margin-top: 20px;
}

h1 {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.link {
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
  color: #000;
}
</style>