<template>
  <div>
    <h4>
      Add Client
    </h4>
    <ErrorMessage v-if="error" :error="error" />
    <div>
      <TextInput
        :placeholder="'Search client name'"
        @onChange="onSearchChange"
      />
      <div>
        <ClientList :name="search" @on-client-click="onClientClick" />
      </div>
    </div>
  </div>
</template>

<script>
import ClientList from '../ClientList';
import ErrorMessage, { getErrorFromResponseError } from '../ErrorMessage';
import TextInput from '../TextInput';
import { addOIDCClientClient } from '../../api/oidc';

export default {
  name: 'OIDCClientClientForm',
  components: {
    ClientList,
    ErrorMessage,
    TextInput,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data()  {
    return {
      search: '',
      error: null,
    };
  },
  methods: {
    onSearchChange(value) {
      this.search = value;
    },
    onClientClick(client) {
      addOIDCClientClient(this.clientId, client.id)
        .then(() => {
          this.$emit('on-create');
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
  },
};
</script>
