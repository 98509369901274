<template>
  <ExternalTemplate :beta="isBeta">
    <div id="header-container">
      <h1>Forgot password</h1>
      <p v-if="!emailSent" style="margin: 0; margin-top: 12px">{{ subheading }}</p>
    </div>
    <div v-if="emailSent" id="success-message">
      <img class="check" src="../assets/img/green-check.min.svg" />
      An email has been sent to {{ email }}
    </div>
    <ErrorMessage v-if="error" :error="error" />
    <form v-if="!emailSent" @submit.prevent="onFormSubmit">
      <EmailInput @onChange="onEmailChange" />
      <div class="input-actions">
        <Button :type="'submit'" :disabled="!validEmail">
          Send Email
          <img :src="sendEmailImg" />
        </Button>
      </div>
      <div class="input-links">
        <a class="link" href="/">Back</a>
      </div>
    </form>
    <div v-if="emailSent" id="back-to">
      <Button :styling="'primary'" :link="'/login.html'">
        Back To Sign In
        <img src="../assets/img/arrow-right.svg" />
      </Button>
    </div>
  </ExternalTemplate>
</template>

<script>
import { forgotPassword } from '../api/sso';
import Button from '../components/Button';
import EmailInput from '../components/EmailInput';
import ErrorMessage, { getErrorFromResponseError } from '../components/ErrorMessage';
import ExternalTemplate from '../components/ExternalTemplate';
import { getEnvironment } from '../utils';

// Button icons
import arrowRight from '../assets/img/arrow-right.svg';
import arrowRightDisabled from '../assets/img/arrow-right-disabled.svg';

const subheading = 'Enter the email address associated with your account';

export default {
  name: 'ForgotPasswordPage',
  components: {
    Button,
    EmailInput,
    ErrorMessage,
    ExternalTemplate,
  },
  data() {
    return {
      email: '',
      emailSent: false,
      error: null,
      isBeta: getEnvironment() !== 'production',
      subheading,
      validEmail: false,
    };
  },
  computed: {
    sendEmailImg() {
      return this.validEmail ? arrowRight : arrowRightDisabled;
    },
  },
  methods: {
    onEmailChange(email, validEmail) {
      this.validEmail = validEmail;
      this.email = email;
    },
    onFormSubmit() {
      this.error = null;
      forgotPassword(this.email)
        .then(() => {
          this.emailSent = true;
        }, (response) => {
          this.error = getErrorFromResponseError(response);
        });
    }
  }
};
</script>

<style scoped>
#header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#header-container > p {
  color: #434343;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

form {
  width: 100%;
  max-width: 311px;
  margin-top: 52px;
}

.input-links {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input-links > .link {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.check {
  margin-right: 8px;
}

#success-message {
  margin-top: 72px;
  color: #434343;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#success-message > img {
  margin-left: 8px;
}

#back-to {
  width: 311px;
  margin-top: 73px;
}

.input-actions {
  margin-top: 32px;
}
</style>
