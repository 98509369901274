<template>
  <div class="card-container">
    <div>
      <Modal
        v-if="showDeleteModal"
        @close="showDeleteModal = false; selectedClientId = null"
      >
        <h4>
          Are you sure you want to delete this OIDC Client?
        </h4>
        <Button
          :styling="'danger'"
          @click="onDeleteConfirmed()"
        >
          Delete
        </Button>
      </Modal>
    </div>
    <div class="client-container">
      <div
        v-for="client in clientList"
        :key="client.id"
        class="card-container mat-card"
      >
        <div class="card-body">
          <div class="flex-content-row">
            <div class="card-section">
              <h4 class="card-title">
                {{ client.name }}
              </h4>
              <h5>
                {{ client.uuid }}
              </h5>
            </div>
            <div class="card-box">
              <Button
                :styling="'primary'"
                :link="`#/aes-tools/oidc/${client.id}`"
              >
                Edit
              </Button>
              <Button
                :styling="'danger'"
                @click="onDelete(client.id)"
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Button from '../Button';
import Modal from '../Modal';

export default {
  name: 'OIDCClientList',
  components: {
    Button,
    Modal,
  },
  props: {
    clientList: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      selectedClientId: null,
      showDeleteModal: false,
    };
  },
  methods: {
    onDelete(clientId) {
      this.selectedClientId = clientId;
      this.showDeleteModal = true;
    },
    onDeleteConfirmed: function() {
      this.$emit('on-delete', this.selectedClientId);
      this.showDeleteModal = false;
      this.selectedClientId = null;
    }
  }
};
</script>

<style scoped>
.small {
  max-width: 80px;
}
</style>
