<template>
  <div class="container">
    <div class="row">
      <ErrorMessage v-if="error" :error="error" />
    </div>
    <div class="row">
      <OIDCClientEdit :client-id="clientId" />
    </div>
  </div>
</template>

<script>
import { getOIDCClient } from '../api/oidc';
import OIDCClientEdit from '../components/oidc/OIDCClientEdit';
import ErrorMessage, { getErrorFromResponseError } from '../components/ErrorMessage';

export default {
  name: 'OIDCClientEditPage',
  components: {
    ErrorMessage,
    OIDCClientEdit,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      client: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    getOIDCClient: function() {
      this.loading = true;
      getOIDCClient()
        .then((response) => {
          this.client = response.data;
          this.loading = false;
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
  },
};
</script>
