<template>
  <div>
    <div class="title">
      <h2>Create OIDC Client</h2>
    </div>
    <ErrorMessage v-if="error" :error="error" />
    <form @submit.prevent="onSubmit">
      <TextInput
        class="text-input"
        :placeholder="'Name'"
        @onChange="onNameChange"
      />
      <Button
        :styling="'primary'"
        :disabled="loading"
        class="submit"
        :type="'submit'"
      >
        Submit
      </Button>
    </form>
  </div>
</template>

<script>
import Button from '../Button';
import ErrorMessage, { getErrorFromResponseError } from '../ErrorMessage';
import TextInput from '../TextInput';
import { createOIDCClient } from '../../api/oidc';

export default {
  name: 'OIDCClientCreateForm',
  components: {
    Button,
    ErrorMessage,
    TextInput,
  },
  data: function() {
    return {
      error: null,
      loading: false,
      name: '',
    };
  },
  methods: {
    onNameChange(value) {
      this.name = value;
    },
    onSubmit() {
      this.error = null;
      this.loading = true;
      createOIDCClient(this.name)
        .then(() => {
          this.loading = false;
          this.$emit('on-create');
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    }
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 32px;
}

.text-input {
  width: 100%;
  margin-bottom: 16px;
}
</style>
