<template>
  <div class="container">
    <ErrorMessage v-if="error" :error="error" />
    <div class="row">
      <div class="row">
        <Button @click="tabClick('info')">
          Info
        </Button>
        <Button @click="tabClick('clients')">
          Clients
        </Button>
        <Button @click="tabClick('domains')">
          Domains 
        </Button>
      </div>
      <hr />
      <div class="row">
        <div v-if="active === 'info'" id="info">
          <div>
            {{ client.name }}
          </div>
          <div>
            {{ client.uuid }}
          </div>
        </div>
        <div v-if="active === 'clients'" id="clients">
          <div>
            <Modal
              v-if="showCreateClientModal"
              @close="showCreateClientModal = false"
            >
              <OIDCClientClientForm
                :client-id="clientId"
                @on-create="onCreateClientClient"
              />
            </Modal>
          </div>
          <div>
            <div>
              <Button @click="addClient()">
                Add Client
              </Button>
            </div>
            <div>
              <div
                v-for="clientItem in client.clients"
                :key="clientItem.id"
                class="list-group-item"
              >
                <div>
                  {{ clientItem.name }}
                </div>
                <div>
                  <Button
                    :styling="'danger'"
                    @click="onClientClientDeleteClick(clientItem.id)"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="active === 'domains'" id="domains">
          <div>
            <Modal
              v-if="showCreateDomainModal"
              @close="showCreateDomainModal = false"
            >
              <OIDCClientDomainForm
                :client-id="clientId"
                @on-create="onCreateDomain"
              />
            </Modal>
          </div>
          <div>
            <div>
              <Button @click="addDomain()">
                Add Domain 
              </Button>
            </div>
            <div>
              <div v-if="loading">
                <Loader />
              </div>
              <div v-else>
                <div
                  v-for="domainItem in client.domains"
                  :key="domainItem.id"
                  class="list-group-item"
                >
                  <div>
                    <div>
                      {{ domainItem.domain }}
                    </div>
                    <div>
                      {{ domainItem.env }}
                    </div>
                  </div>
                  <div>
                    <Button
                      :styling="'danger'"
                      @click="onDomainDeleteClick(domainItem.id)"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import OIDCClientClientForm from './OIDCClientClientForm';
import OIDCClientDomainForm from './OIDCClientDomainForm';
import ErrorMessage, { getErrorFromResponseError } from '../ErrorMessage';
import Loader from '../Loader';
import Modal from '../Modal';
import { getOIDCClient, deleteOIDCDomain, removeOIDCClientClient } from '../../api/oidc';

export default {
  name: 'OIDCClientEdit',
  components: {
    ErrorMessage,
    Loader,
    Modal,
    OIDCClientClientForm,
    OIDCClientDomainForm,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      active: 'info',
      client: [],
      error: null,
      loading: false,
      showCreateClientModal: false,
      showCreateDomainModal: false,
    };
  },
  created: function() {
    this.getOIDCClient();
  },
  methods: {
    getOIDCClient() {
      this.loading = true;
      getOIDCClient(this.clientId)
        .then((response) => {
          this.client = response.data;
          this.loading = false;
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
    tabClick(buttonType) {
      this.active = buttonType;
    },
    addClient() {
      this.showCreateClientModal = true;
    },
    addDomain() {
      this.showCreateDomainModal = true;
    },
    onCreateDomain() {
      this.getOIDCClient();
      this.showCreateDomainModal = false;
    },
    onDomainDeleteClick(domainId) {
      deleteOIDCDomain(this.clientId, domainId)
        .then(() => {
          this.getOIDCClient();
        });
    },
    onClientClientDeleteClick(clientClientId) {
      removeOIDCClientClient(this.clientId, clientClientId)
        .then(() => {
          this.getOIDCClient();
        });
    },
    onCreateClientClient() {
      this.getOIDCClient();
      this.showCreateClientModal = false;
    },
  }
};
</script>
