<template>
  <div>
    <h4>
      Add Domain
    </h4>
    <ErrorMessage v-if="error" :error="error" />
    <form @submit.prevent="submit">
      <TextInput
        :placeholder="'Domain'"
        @onChange="(value) => { domain = value }"
      />
      <Button :type="'submit'">
        Add
      </Button>
    </form>
  </div>
</template>

<script>
import { createOIDCClientDomain } from '../../api/oidc';
import Button from '../Button';
import ErrorMessage, { getErrorFromResponseError } from '../ErrorMessage';
import TextInput from '../TextInput';

export default {
  name: 'OIDCClientDomainForm',
  components: {
    Button,
    ErrorMessage,
    TextInput,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      domain: '',
      env: '',
      error: null,
    };
  },
  methods: {
    submit() {
      this.error = null;
      createOIDCClientDomain(this.clientId, this.domain, this.env)
        .then(() => {
          this.$emit('on-create');
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
  }
};
</script>
