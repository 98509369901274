import client from './';

export const getIsSSOEnabled = (username, env) => {
  return client.get('/oidc/check-username', { params: { username, env }});
};

export const login = (username, password, rememberMe = false, appVersion = '1.0', loginAppType = 'web') => {
  const data = {
    username,
    password,
    rememberMe,
    appVersion,
    loginAppType,
  };

  return client.post('/login', data);
};

export const forgotPassword = (username, appVersion = '1.0', loginAppType = 'web') => {
  const data = {
    username,
    appVersion,
    loginAppType,
  };

  return client.post('/send-reset-password', data);
};
