<template>
  <div :class="['input', { 'float': shouldFloat }]">
    <Label v-if="shouldFloat" :styling="error ? 'danger' : 'default'">
      {{ placeholder }}
    </Label>
    <div style="position: relative">
      <input
        :name="name"
        :placeholder="active ? '' : placeholder"
        :style="error ? 'border-bottom-color: #be2b1f' : ''"
        :type="type"
        :value="value"
        @blur="onInputBlur"
        @input="onInputChange"
        @focus="onInputFocus"
      />
      <slot />
    </div>
    <Label
      v-if="error !== null"
      :style="{ lineHeight: '22px', marginTop: '4px' }"
      :styling="'danger'"
    >{{ error }}</Label>
  </div>
</template>

<script>
import Label from './Label';

export default {
  name: 'Input',
  components: {
    Label
  },
  props: {
    error: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    shouldFloat() {
      const filled = Boolean(this.value);

      return this.active || filled;
    },
  },
  methods: {
    onInputBlur() {
      this.active = false;
      this.$emit('onInputBlur');
    },
    onInputChange(event) {
      this.$emit('onInputChange', event.target.value);
    },
    onInputFocus() {
      this.active = true;
      this.$emit('onInputFocus');
    },
  },
};
</script>

<style scoped>
.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.input.float {
  padding-top: 0;
}

.input img {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  font-family: Solis, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 4px 0 8px;
}

input:focus {
  outline: none;
}

input:placeholder-shown {
  border-bottom-color: #ccc;
}

input::placeholder {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  outline: none;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -moz-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}
</style>
