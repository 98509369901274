<template>
  <div class="spin-screen">
    <div class="sp-spinner">
      <div class="bounce1" />
      <div class="bounce2" />
      <div class="bounce3" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped>
</style>
