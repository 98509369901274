<template>
  <LoginErrorPage v-if="sessionError" />
  <ExternalTemplate v-else :beta="isBeta">
    <h1>Web Portal Sign in</h1>
    <form @submit.prevent="onFormSubmit">
      <div v-if="!checkedSSOEnabled">
        <EmailInput :value="email" @onChange="onEmailChange" />
        <div class="input-group-checkbox">
          <input v-model="rememberMe" tabindex="3" type="checkbox" />
          <label>Remember me</label>
        </div>
      </div>
      <div v-else>
        <div id="email-display" @click="onEmailDisplayClick">
          <Label>Email</Label>
          <div class="bold">{{ email }}</div>
        </div>
        <PasswordInput id="password-input" :error="loginError" @onChange="onPasswordChange" />
      </div>
      <div class="input-actions">
        <Button :type="'submit'" :disabled="!canContinue">
          {{ submitButtonText }}
          <img :src="arrowImg" />
        </Button>
      </div>
      <div v-if="checkedSSOEnabled">
        <div class="input-links">
          <a class="link" href="/login.html">Back</a>
        </div>
        <div id="forgot-password">
          <a class="link" href="/forgot-password.html">Forgot Password</a>
        </div>
      </div>
    </form>
  </ExternalTemplate>
</template>

<script>
import LoginErrorPage from './LoginErrorPage';
import { getIsSSOEnabled, login } from '../api/sso';
import Button from '../components/Button';
import EmailInput from '../components/EmailInput';
import ExternalTemplate from '../components/ExternalTemplate';
import Label from '../components/Label';
import PasswordInput from '../components/PasswordInput';
import { getErrorFromResponseError } from '../components/ErrorMessage';
import { getEnvironment, isValidEmail } from '../utils';

// Button icons
import arrowRight from '../assets/img/arrow-right.svg';
import arrowRightDisabled from '../assets/img/arrow-right-disabled.svg';

export default {
  name: 'LoginPage',
  components: {
    Button,
    EmailInput,
    ExternalTemplate,
    Label,
    LoginErrorPage,
    PasswordInput,
  },
  data() {
    const { email, sessionError, url } = this.getQueryValues();

    return {
      email,
      password: '',
      rememberMe: false,
      loading: false,
      url,

      validEmail: Boolean(email),
      validPassword: true,
      loginError: null,

      sessionError,

      checkedSSOEnabled: false,
      isBeta: getEnvironment() !== 'production',
    };
  },
  computed: {
    arrowImg() {
      return this.canContinue ? arrowRight : arrowRightDisabled;
    },
    canContinue() {
      return (this.password !== '' || !this.checkedSSOEnabled) && this.validEmail;
    },
    showPasswordSection() {
      return this.checkedSSOEnabled;
    },
    submitButtonText() {
      return this.checkedSSOEnabled ? 'Sign In' : 'Continue';
    },
  },
  created() {
    if (isValidEmail(this.email)) {
      this.checkSSOEnabledProcess();
    }
  },
  methods: {
    // get the login_hint query param from the url
    getQueryValues() {
      const urlParams = new URLSearchParams(window.location.search);
      let email = urlParams.get('login_hint');
      let sessionError = urlParams.get('error');
      let url = urlParams.get('url') || '';
      if (url.indexOf('/') === 0) {
        url = url.substring(1);
      }

      return { email, sessionError, url: `/#/${url}`};
    },
    onEmailChange(email, validEmail) {
      this.email = email;
      this.validEmail = validEmail;
    },
    onEmailDisplayClick() {
      this.checkedSSOEnabled = false;
    },
    onPasswordChange(password, validPassword) {
      this.password = password;
      this.validPassword = validPassword;

      // reset login error, if any
      this.loginError = null;
    },
    onFormSubmit() {
      if (!this.checkedSSOEnabled) {
        this.checkSSOEnabledProcess();
      } else {
        this.loginProcess();
      }
    },
    checkSSOEnabledProcess() {
      getIsSSOEnabled(this.email, getEnvironment())
        .then((response) => {
          const { oidcEnabled, authURL } = response.data;

          if (oidcEnabled) {
            this.setCookies();
            window.location = authURL;
            return;
          }

          // TODO: this is supposed to work in modern browsers but need to check ie 11
          var searchParams = new URLSearchParams(window.location.search);
          if (searchParams.get('login_hint') !== this.email) {
            searchParams.set('login_hint', this.email);
            window.location.search = searchParams.toString();
          } else {
            this.checkedSSOEnabled = true;
          }
        }, (error) => {
          const { message } = getErrorFromResponseError(error);
          this.loginError = message;
        });
    },
    setCookies() {
      this.$cookies.set('loginAppType', 'web');
      this.$cookies.set('rememberMe', this.rememberMe);
      this.$cookies.remove('url');
      this.$cookies.set('url', this.url);
    },
    loginProcess() {
      login(this.email, this.password, this.rememberMe)
        .then(() => {
          window.location = this.url;
        }, (error) => {
          const { message } = getErrorFromResponseError(error);
          this.loginError = message;
        });
    }
  }
};
</script>

<style scoped>
form {
  width: 100%;
  max-width: 311px;
  margin-top: 52px;
}

.input-group-checkbox {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-group-checkbox > label {
  color: #000000;
  font-size: 12px;
  letter-spacing: 0.17px;
}

input[type="checkbox"] {
  margin-left: 0px;
  margin-right: 12px;
  cursor: pointer;
}

#email-display {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#password-input {
  margin-top: 32px;
}

.input-actions {
  margin: 32px 0 0;
}

.bold {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.input-links {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input-links > .link {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

#forgot-password {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#forgot-password > .link {
  color: #f05514;
}
</style>
