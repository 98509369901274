import client from './';

export const activateAccount = (username, password, token) => {
  const data = {
    username,
    password,
    token
  };

  return client.post('/user-activate', data);
};

export const checkToken = (username, token) => {
  const data = {
    username,
    token
  };

  return client.post('/check-user-account-token', data);
};