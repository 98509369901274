<template>
  <Input
    :error="error"
    :placeholder="placeholder"
    :name="name"
    :type="'text'"
    :value="text"
    @onInputChange="onTextChange"
  />
</template>

<script>
import Input from './Input';

export default {
  name: 'TextInput',
  components: {
    Input
  },
  props: {
    error: {
      type: String,
      default: null
    },
    name: String,
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
  },
  data(props) {
    return {
      text: props.value || ''
    };
  },
  methods: {
    onTextChange(text) {
      this.text = text;
      this.$emit('onChange', text);
    }
  }
};
</script>