<template>
  <div class="beta-statement">
    <p>
      <strong>Note:</strong>
      You are being provided access to the Beta version of the Portal.
      You acknowledge and agree that the Portal is currently in Beta testing
      and is a pre-release version that is not commercially available and
      which may be incomplete and/or have certain areas that may not be
      functioning or may be operating at limited capacity. The Beta version
      of the Portal is being furnished to You “AS IS” to be used solely for
      Your internal testing and evaluation purposes and should not be relied
      upon for any purpose. Your use of the Portal is at your sole risk and
      without warranty of any kind. Any comments and feedback that You provide
      would be greatly appreciated. You agree that the Portal contains valuable
      trade secrets and intellectual property which belong to AccuWeather For
      Business and which are to be treated as confidential information and not
      to be disclosed to others. Your use of the Portal is subject to these terms.
      If You do not agree with them, please do not access the Portal.
    </p>
  </div>
</template>

<script>
export default {
  name: 'BetaStatement',
};
</script>

<style scoped>
.beta-statement {
  max-width: 800px;
  margin-top: 60px;
  color: #333a42;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
}

.beta-statement > p {
  margin: 0;
}
</style>
