<template>
  <div class="alert alert-danger">
    <h4>{{ error.message }}</h4>
    <p>{{ error.details }}</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};

// gets an error from a promise api response error
export const getErrorFromResponseError = (error) => {
  const {
    // this is a generic error if the server is down and doesnt
    // give us an error response
    message = 'An error has occurred. Please contact your administrator',
    details
  } = error.response.data;

  return {
    message,
    details,
  };
};
</script>

<style scoped>
.alert {
  padding: 8px;
}

.alert-danger {
  color: #cf0a0a;
}
</style>
