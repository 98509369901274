<template>
  <div :class="['textarea', { 'danger': error }]">
    <label>{{ label }}</label>
    <textarea :name="name" @input="onTextChange" />
    <Label
      v-if="error"
      :styling="'danger'"
      :style="{ lineHeight: '22px', marginTop: '4px' }"
    >{{ error }}</Label>
  </div>
</template>

<script>
import Label from '../components/Label';

export default {
  name: 'TextArea',
  components: {
    Label
  },
  props: {
    error: String,
    label: String,
    name: String,
    value: String
  },
  data(props) {
    return {
      text: props.value || ''
    };
  },
  methods: {
    onTextChange(event) {
      this.$emit('onChange', event.target.value);
    }
  }
};
</script>

<style>
.textarea {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.textarea.danger > label {
  color: #be2b1f; 
}

.textarea.danger > textarea {
  border-color: #be2b1f;
}

label {
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}

textarea {
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Solis;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  padding: 12px;
  resize: vertical;
}

textarea:focus {
  outline: none;
}
</style>