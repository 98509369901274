<template>
  <label :class="styling">
    <slot />
  </label>
</template>

<script>
export default {
  name: 'Label',
  props: {
    styling: {
      type: String,
      default: 'default',
      validator: (value) => {
        const options = ['danger', 'default'];
        
        return options.includes(value);
      }
    },
  },
};
</script>

<style scoped>
label {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.danger {
  color: #be2b1f;
}

.default {
  color: #000;
}
</style>
