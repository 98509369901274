<template>
  <ExternalTemplate>
    <div id="header-container">
      <h1>Contact Support</h1>
    </div>
    <form @submit.prevent="onFormSubmit">
      <TextInput :name="'name'" :placeholder="'Name'" @onChange="onNameChange" />
      <EmailInput @onChange="onEmailChange" />
      <Input
        :error="errors.phone"
        :name="'phone'"
        :placeholder="'Phone Number'"
        :type="'tel'"
        :value="form.phone"
        @onInputChange="onPhoneChange" />
      <TextInput :name="'company'" :placeholder="'Company Name'" @onChange="onCompanyChange" />
      <TextArea
        id="comments"
        :error="errors.comments"
        :label="'Comments or questions'"
        :name="'comments'"
        @onChange="onCommentsChange"
      />
      <div id="recaptcha">
        <vue-recaptcha
          :sitekey="siteKey"
          @verify="onVerify"
          @expired="onExpire"
        />
      </div>
      <div class="button">
        <Button :type="'submit'" :disabled="!canSubmit">
          {{ 'Send Email' }}
          <img :src="arrowImg" />
        </Button>
        <div class="input-links">
          <a class="link" href="/login.html">Back</a>
        </div>
      </div>
    </form>
  </ExternalTemplate>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

import { contactSupport } from '../api/support';
import Button from '../components/Button';
import EmailInput from '../components/EmailInput';
import ExternalTemplate from '../components/ExternalTemplate';
import Input from '../components/Input';
import TextArea from '../components/TextArea';
import TextInput from '../components/TextInput';
import { isValidPhone } from '../utils';

import arrowRight from '../assets/img/arrow-right.min.svg';
import arrowRightDisabled from '../assets/img/arrow-right-disabled.min.svg';

const siteKey = '6LdAw3sUAAAAAL_75ynHkt6qhniMHafSZ4kegDqt';

export default {
  name: 'ActivateAccountPage',
  components: {
    Button,
    EmailInput,
    ExternalTemplate,
    Input,
    TextArea,
    TextInput,
    VueRecaptcha
  },
  data() {
    return {
      errors: {
        comments: null,
        company: null,
        email: null,
        name: null,
        phone: null
      },
      form: {
        comments: '',
        company: '',
        email: '',
        name: '',
        phone: ''
      },
      siteKey,
      token: null,
      verified: false
    };
  },
  computed: {
    arrowImg() {
      return this.canSubmit ? arrowRight : arrowRightDisabled;
    },
    canSubmit() {
      let valid = true;

      for (let key of Object.keys(this.errors)) {
        if (this.errors[key] || !this.form[key]) {
          valid = false;
        }
      }

      return this.verified && valid;
    }
  },
  methods: {
    onCommentsChange(comments) {
      this.form.comments = comments;
    },
    onCompanyChange(company) {
      this.form.company = company;
    },
    onEmailChange(email, validEmail) {
      this.form.email = email;
      
      if (!validEmail) {
        this.errors.email = true;
      } else {
        this.errors.email = null;
      }
    },
    onExpire() {
      this.verified = false;
      this.token = null;
    },
    onFormSubmit() {
      if (this.canSubmit) {
        contactSupport(this.form, this.token).then(
          () => {
            window.location.href = `/login.html?login_hint=${this.form.email}`;
          }
        );
      }
    },
    onNameChange(name) {
      this.form.name = name;
    },
    onPhoneChange(phone) {
      this.form.phone = phone;

      if (!isValidPhone(phone)) {
        this.errors.phone = 'This is not a valid phone number';
      } else {
        this.errors.phone = null;
      }
    },
    onVerify(response) {
      this.token = response;
      this.verified = true;
    }
  }
};
</script>

<style scoped>
form {
  width: 100%;
  max-width: 311px;
  margin-top: 52px;
}

#comments {
  margin-top: 40px;
}

/* >>> is a deep selector */
/* Source: https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors */
form >>> textarea {
  min-height: 238px;
}

#recaptcha {
  margin-top: 40px;
}

.button {
  margin-top: 32px;
}

.input-links {
  margin-top: 16px;
}

.input-links > .link {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
</style>