<template>
  <div class="container">
    <div class="row">
      <h1>OIDC Client</h1>
    </div>
    <div class="row">
      <Button @click="showCreateClient">
        Create
      </Button>
    </div>
    <ErrorMessage v-if="error" :error="error" />
    <div class="row">
      <Modal
        v-if="showCreateModal"
        @close="showCreateModal = false"
      >
        <OIDCClientCreateForm @on-create="onClientCreated" />
      </Modal>
    </div>
    <div class="row">
      <OIDCClientList
        :client-list="clientList"
        @on-delete="onDeleteClient"
      />
    </div>
  </div>
</template>

<script>
import OIDCClientList from '../components/oidc/OIDCClientList';
import OIDCClientCreateForm from '../components/oidc/OIDCClientCreateForm';
import ErrorMessage, { getErrorFromResponseError } from '../components/ErrorMessage';
import Modal from '../components/Modal';
import { getOIDCClientList, deleteOIDCClient } from '../api/oidc';

export default {
  name: 'OIDCClientListPage',
  components: {
    ErrorMessage,
    Modal,
    OIDCClientCreateForm,
    OIDCClientList,
  },
  data: function() {
    return {
      showCreateModal: false,
      clientList: [],
      loading: false,
      error: null,
    };
  },
  created: function() {
    this.getOIDCClientList();
  },
  methods: {
    getOIDCClientList: function() {
      this.loading = true;
      this.error = null;
      getOIDCClientList()
        .then((response) => {
          this.clientList = response.data;
          this.loading = false;
        }, (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
    showCreateClient() {
      this.showCreateModal = true;
    },
    onClientCreated() {
      this.showCreateModal = false;
      this.getOIDCClientList();
    },
    onDeleteClient(clientId) {
      this.error = null;
      deleteOIDCClient(clientId)
        .then(() => this.getOIDCClientList(), (error) => {
          this.error = getErrorFromResponseError(error);
        });
    },
  }
};
</script>
