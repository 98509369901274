<template>
  <a v-if="link" :class="buttonClass" :href="link">
    <slot />
  </a>
  <button
    v-else
    :class="buttonClass"
    :disabled="disabled"
    :type="type"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    styling: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'button',
    }
  },
  computed: {
    buttonClass() {
      const baseClass = 'button';

      switch (this.styling) {
        case 'danger':
          return `${baseClass} button-danger`;
        default:
          return `${baseClass} button-primary`;
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.button {
  width: 100%;
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  font-family: Solis, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 13px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button:disabled {
  background-color: #f1f4f8;
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.button > img {
  margin-left: 4px;
}

a.button {
  text-decoration: none;
}

.button-primary {
  background-color: #333a42;
}

.button-danger {
  background-color: #d9534f;
}
</style>
