<template>
  <Input
    :error="error"
    :name="'email'"
    :placeholder="placeholder"
    :type="'email'"
    :value="email"
    @onInputChange="onEmailChange" 
  />
</template>

<script>
import Input from './Input';
import { isValidEmail } from '../utils';

export default {
  name: 'EmailInput',
  components: {
    Input,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data(props) {
    return {
      email: props.value || '',
      placeholder: 'Email'
    };
  },
  computed: {
    error() {
      const hasError = this.email !== '' && !this.validEmail;

      return hasError ? 'This is not a valid email' : null;
    },
    validEmail() {
      return isValidEmail(this.email);
    }
  },
  created() {
    if (this.email !== '') {
      this.$emit('onChange', this.email, this.validEmail);
    }
  },
  methods: {
    onEmailChange(email) {
      this.email = email;
      this.$emit('onChange', this.email, this.validEmail);
    }
  }
};
</script>